import React from "react";

import { useTranslation, Trans } from "react-i18next";

import t1 from '../../img/Home/t1.png'
import t2 from '../../img/Home/t2.png'

import c1 from '../../img/Home/content01.png'
import c2 from '../../img/Home/content02.png'
import c3 from '../../img/Home/content03.png'
import c4 from '../../img/Home/content04.png'

import Fade from "react-reveal/Fade";

function TradeZen () {

    const { t } = useTranslation();
 
    return(
        <>
            <div className="mt-[80px] xl:mt-[160px] overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto">
                <Fade right><img src={t1} alt="" className="mt-[120px] hidden xl:block"/></Fade>
                <div className="xl:absolute top-0 right-0 bg-white xl:w-[650px] h-[500px] xl:h-[586px] rounded-[40px] flex flex-col items-center justify-center">
                    <p className="roboto text-[24px] xl:text-[40px]">{t('main.17')}</p>
                    <div className="grid grid-cols-2 gap-x-[50px] gap-y-[16px] mt-[40px] ml-[20px]">
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.18')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.19')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.20')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.21')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.22')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.23')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.24')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.25')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.26')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.27')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.28')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.29')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.30')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.31')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.32')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.33')}</p>
                        <p className="text-[#000000B3] font-[300] roboto text-[14px] xl:text-[16px]">{t('main.34')}</p>
                        <p className="text-[#000000B3] font-[500] roboto text-[14px] xl:text-[16px]">{t('main.35')}</p>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[20px] xl:mt-0">
                <Fade left><img src={t2} alt="" className="mt-[60px] ml-auto hidden xl:block"/></Fade>
                <div className="xl:absolute top-[120px] left-0 bg-white xl:w-[703px] h-[500px] xl:h-[642px] rounded-[40px] flex flex-col items-center justify-center">
                    <p className="roboto text-[24px] xl:text-[40px]"><Trans>{t('main.36')}</Trans></p>
                    <div className="grid grid-cols-2 xl:ml-[60px] gap-y-[60px] mt-[30px] xl:mt-[60px] mx-[20px] xl:mx-0">
                        <div>
                            <img src={c1} alt="" />
                            <p className="roboto text-[14px] xl:text-[20px] max-w-[172px] mt-[20px]">{t('main.37')}</p>
                        </div>
                        <div>
                            <img src={c2} alt="" />
                            <p className="roboto text-[14px] xl:text-[20px] max-w-[172px] mt-[20px]">{t('main.38')}</p>
                        </div>
                        <div>
                            <img src={c3} alt="" />
                            <p className="roboto text-[14px] xl:text-[20px] max-w-[172px] mt-[20px]">{t('main.39')}</p>
                        </div>
                        <div>
                            <img src={c4} alt="" />
                            <p className="roboto text-[14px] xl:text-[20px] max-w-[267px] mt-[20px]">{t('main.40')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
       
    );
}

export default TradeZen;