import React from "react";

import { useTranslation } from "react-i18next";

import vector from '../../img/Home/Vectorr.png'

import Marquee from "react-fast-marquee";

import m1 from '../../img/Home/marque/m1.png'
import m2 from '../../img/Home/marque/m2.png'
import m3 from '../../img/Home/marque/m3.png'
import m4 from '../../img/Home/marque/m4.png'
import m5 from '../../img/Home/marque/m5.png'
import m6 from '../../img/Home/marque/m6.png'
import m7 from '../../img/Home/marque/m7.png'

import Zoom from "react-reveal/Zoom";

const marque = [
    {
        img: m1,
    },
    {
        img: m2,
    },
    {
        img: m3,
    },
    {
        img: m4,
    },
    {
        img: m5,
    },
    {
        img: m6,
    },
    {
        img: m7,
    },
]

function Our () {

    const { t } = useTranslation();
 
    return(
        <div className="mt-[80px] xl:mt-[160px] overflow-hidden relative">
            <div className="flex justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <p className="roboto text-[24px] xl:text-[40px]">{t('main.58')}</p>
                <p className="roboto text-[24px] xl:text-[40px]">{t('main.59')}</p>
            </div>
            <Zoom bottom right duration={2000}><img src={vector} alt="" className="mx-auto mt-[40px] scale-75 xl:scale-100"/></Zoom>
            <Marquee autoFill className="absolute top-[-280px]">
                {marque.map((img, index) => (
                    <div key={index} className="w-[152.868px] h-[68px] xl:w-[290px] xl:h-[129px] rounded-[40px] bg-white mx-[10px] flex flex-col items-center justify-center">
                        <img src={img.img} alt="" className="scale-75 xl:scale-100"/>
                    </div>
                ))}
            </Marquee>
        </div>
    );
}

export default Our;