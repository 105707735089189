import React from "react";

import { useTranslation } from "react-i18next";

import group from '../../img/Banking/group.png'

function HereShow () {

    const { t } = useTranslation();
 
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden relative pt-[80px] xl:pt-[160px] justify-between flex flex-col-reverse xl:flex-row items-center">
            <div className="mt-[20px] xl:mt-0">
                <img src={group} alt="" />
            </div>
            <div className="">
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.4] max-w-[550px]">{t('banking.8')}</p>
                <div className="flex mt-[30px] xl:mt-[60px] gap-[75px] items-center">
                    <p className="bebas text-[#CECECE] text-[32px] xl:text-[100px]">01</p>
                    <p className="max-w-[187px] roboto text-[16px] xl:text-[20px] font-[300]">{t('banking.9')}</p>
                </div>
                <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="600" height="2" viewBox="0 0 600 2" fill="none">
                    <path d="M0 1H600" stroke="url(#paint0_linear_2283_2333)"/>
                    <defs>
                        <linearGradient id="paint0_linear_2283_2333" x1="0" y1="1.5" x2="600" y2="1.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CECECE"/>
                        <stop offset="1" stop-color="#606060"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div className="flex mt-[30px] xl:mt-[60px] gap-[75px] items-center">
                    <p className="bebas text-[#CECECE] text-[32px] xl:text-[100px]">02</p>
                    <p className="max-w-[332px] roboto text-[16px] xl:text-[20px] font-[300]">{t('banking.10')}</p>
                </div>
                <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="600" height="2" viewBox="0 0 600 2" fill="none">
                    <path d="M0 1H600" stroke="url(#paint0_linear_2283_2333)"/>
                    <defs>
                        <linearGradient id="paint0_linear_2283_2333" x1="0" y1="1.5" x2="600" y2="1.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CECECE"/>
                        <stop offset="1" stop-color="#606060"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div className="flex mt-[30px] xl:mt-[60px] gap-[75px] items-center">
                    <p className="bebas text-[#CECECE] text-[32px] xl:text-[100px]">03</p>
                    <p className="max-w-[393px] roboto text-[16px] xl:text-[20px] font-[300]">{t('banking.11')}</p>
                </div>
            </div>
        </div>
    );
}

export default HereShow;