import React from "react";

import { useTranslation, Trans } from "react-i18next";

import w1 from '../../img/Home/why1.png'
import w2 from '../../img/Home/why2.png'

import Fade from "react-reveal/Fade";

function Whydoover () {

    const { t } = useTranslation();
 
    return(
        <div className="mt-[80px] xl:mt-[160px] overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto">
            <p className="roboto max-w-[550px] text-[24px] xl:text-[40px] leading-[1.2]">{t('main.10')}</p>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px]">
                <Fade left><div>
                    <div className="xl:w-[497px] pb-[40px] xl:pb-0 xl:h-[547px] rounded-[40px] bg-white">
                        <div className="h-px"></div>
                        <div className="w-[160px] xl:w-[242px] h-[1px] bg-[#606060] mx-[10px] xl:mx-[40px] mt-[30px] xl:mt-[60px]"></div>
                        <p className="roboto text-[16px] xl:text-[24px] roboto mx-[10px] xl:mx-[40px]"><Trans>{t('main.11')}</Trans></p>
                        <img src={w1} alt="" className="max-w-[calc(100%-20px)] mx-[10px] xl:mx-[40px] mt-[-60px] xl:mt-[-75px]"/>
                        <p className="mx-[10px] xl:mx-[40px] roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300] mt-[40px]">{t('main.12')}</p>
                    </div>
                </div></Fade>
                <div className="flex flex-col justify-between mt-[20px] xl:mt-0">
                    <Fade right delay={700}><div className="xl:w-[703px] pb-[40px] xl:pb-0 xl:h-[243px] rounded-[40px] bg-white flex flex-col xl:flex-row items-center justify-center gap-[20px] xl:gap-[60px]">
                        <img src={w2} alt="" className="w-[calc(100%-20px)] xl:w-auto mt-[10px]"/>
                        <div className="mx-[10px] xl:mx-0">
                            <p className="roboto text-[16px] xl:text-[24px]"><Trans>{t('main.13')}</Trans></p>
                            <p className="roboto font-[300] text-[14px] xl:text-[16px] xl:max-w-[300px] mt-[13px]">{t('main.14')}</p>
                        </div>
                    </div></Fade>
                    <Fade right delay={1200}><div className="xl:w-[703px] pb-[20px] xl:pb-0 xl:h-[243px] rounded-[40px] bg-white flex flex-col justify-center mt-[20px] xl:mt-0">
                        <div className="xl:ml-[60px] xl:mr-[40px] flex flex-col mt-[20px]">
                            <p className="roboto text-[16px] xl:text-[24px] mx-[20px] xl:mx-0"><Trans>{t('main.15')}</Trans></p>
                            <p className="roboto font-[300] text-[14px] xl:text-[16px] max-w-[325px] mt-[30px] xl:mt-[65px] xl:ml-auto mx-[20px] xl:mx-0">{t('main.16')}</p> 
                        </div>
                    </div></Fade>
                </div>
            </div>
        </div>
    );
}

export default Whydoover;