import React from "react";

import { useTranslation } from "react-i18next";

import book from '../../img/Banking/book.png'

import Fade from 'react-reveal/Fade'

function WhatDoes () {

    const { t } = useTranslation();
 
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden relative mt-[80px] xl:mt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between items-center">
                <p className="text-[20px] xl:text-[40px] roboto leading-[1.4] max-w-[600px]">{t('banking.12')}</p>
                <p className="text-[14px] xl:text-[16px] roboto font-[300] max-w-[497px] text-[#000000B2] mt-[10px] xl:mt-0">{t('banking.13')}</p>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px]">
                <Fade left delay={500}><div className="relative">
                    <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="581" height="311" viewBox="0 0 581 311" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 291C0.000244141 302.046 8.95459 311 20.0002 311H412.719C423.765 311 432.719 302.046 432.719 291V206.034C432.719 194.988 441.673 186.034 452.719 186.034H561C572.046 186.034 581 177.079 581 166.034V20.4496C581 9.4039 572.046 0.449585 561 0.449585H20.0002C8.95453 0.449585 0.000244141 9.40387 0.000244141 20.4496V291Z" fill="white"/>
                    </svg>
                    <div className="xl:absolute top-[75px] left-[40px]">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('banking.14')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] font-[300] max-w-[367px] mt-[12px]">{t('banking.15')}</p>
                    </div>
                </div></Fade>
                <Fade right delay={700}><div className="relative">
                    <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="581" height="311" viewBox="0 0 581 311" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M581 290.55C581 301.596 572.045 310.55 561 310.55H168.281C157.235 310.55 148.281 301.596 148.281 290.55V205.584C148.281 194.538 139.327 185.584 128.281 185.584H20C8.95431 185.584 0 176.63 0 165.584V19.9999C0 8.95419 8.9543 -0.00012207 20 -0.00012207H561C572.045 -0.00012207 581 8.95416 581 19.9998V290.55Z" fill="white"/>
                    </svg>
                    <div className="xl:absolute top-[75px] right-[120px] mt-[20px] xl:mt-0">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('banking.16')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] font-[300] max-w-[367px] mt-[12px]">{t('banking.17')}</p>
                    </div>
                </div></Fade>
            </div>
            <div className="w-[302px] h-[255px] rounded-[20px] bg-white absolute left-1/2 -translate-x-1/2 top-[410px] overflow-hidden hidden xl:block">
                <Fade bottom>
                    <img src={book} alt="" />
                </Fade>
            </div>
            <div className="flex flex-col xl:flex-row justify-between xl:mt-[120px]">
                <Fade left delay={900}><div className="relative">
                    <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="581" height="311" viewBox="0 0 581 311" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 20C0.000244141 8.95431 8.95459 0 20.0002 0H412.719C423.765 0 432.719 8.95431 432.719 20V104.966C432.719 116.012 441.673 124.966 452.719 124.966H561C572.046 124.966 581 133.921 581 144.966V290.55C581 301.596 572.046 310.55 561 310.55H20.0002C8.95453 310.55 0.000244141 301.596 0.000244141 290.55V20Z" fill="white"/>
                    </svg>
                    <div className="xl:absolute top-[120px] left-[40px] mt-[20px] xl:mt-0">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('banking.18')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] font-[300] max-w-[367px] mt-[12px]">{t('banking.19')}</p>
                    </div>
                </div></Fade>
                <Fade right delay={1100}><div className="relative">
                    <svg className="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="581" height="311" viewBox="0 0 581 311" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M581 20.4497C581 9.40401 572.045 0.449707 561 0.449707H168.281C157.235 0.449707 148.281 9.40401 148.281 20.4497V105.416C148.281 116.462 139.327 125.416 128.281 125.416H20C8.95431 125.416 0 134.37 0 145.416V291C0 302.046 8.9543 311 20 311H561C572.045 311 581 302.046 581 291V20.4497Z" fill="white"/>
                    </svg>
                    <div className="xl:absolute top-[120px] right-[40px] mt-[20px] xl:mt-0">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('banking.20')}</p>
                        <p className="text-[14px] xl:text-[16px] roboto text-[#000000B2] font-[300] max-w-[367px] mt-[12px]">{t('banking.21')}</p>
                    </div>
                </div></Fade>
            </div>
        </div>
    );
}

export default WhatDoes;