import React from 'react';

import { useTranslation } from "react-i18next";

import logo from '../img/logo_black.png'

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#E8E8E8]'>
      <div className='z-10 relative max-w-[1280px] mx-auto'>
        <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
          <img src={logo} alt="" className='w-[50px] h-[25px] ml-[30px] xl:ml-0'/>
          <div className='ml-[30px] xl:ml-[180px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
            <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer1')} +441294620297</p>
            <div>
              <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer2')} Carpenter Court, 1 Maple Road Bramhall, Stockport, Cheshire, SK7 2DH</p>
            </div>
            <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer3')} support@ameno-platform.com</p>
          </div>
          <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
            <a href="/TermsandConditions.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer4')}</p></a>
            <a href="/PrivacyPolicy.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer5')}</p></a>
            <a href="/AML.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer6')}</p></a>
          </div>
        </div>
        <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[230px] pt-[40px]'>
          <p className='text-[#000000B2] roboto text-[12px] font-normal'>{t('footer7')}</p>
          <p className='text-[#000000B2] roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer8')}</p>
          <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] text-[#000000B2] pr-[30px] xl:pr-0'>Ameno © 2024. All rights reserved.</p>      
        </div>
      </div>
    </div>

  
  );
}

export default Footer;