import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import WhyTrade from "../components/AboutUs/WhyTrade";
import Ready from "../components/Home/Ready";

function AboutUs () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <WhyTrade/>
            <Ready/>
        </div>
    );
}

export default AboutUs;