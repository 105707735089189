import React from "react";

import { useTranslation, Trans } from "react-i18next";

import reffer from '../../img/ReferralProgram/reffer.png'

import Fade from "react-reveal/Fade";

function Earn () {

    const { t } = useTranslation();
 
    return(
        <div className="relative mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <p className="text-[32px] xl:text-[40px] leading-[1.2] roboto mx-auto">{t('referralprogram.4')}</p>
                <p className="mx-auto max-w-[600px] text-[14px] xl:text-[16px] roboto font-[300] text-[#000000B2] mt-[20px] z-50 text-center">{t('referralprogram.5')}</p>
                <div className="flex flex-col xl:flex-row gap-[20px] xl:mx-auto mt-[20px] xl:mt-[40px]">
                    <div className="xl:w-[445px] h-[175px] rounded-[40px] bg-white flex flex-col justify-center items-center">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('referralprogram.6')}</p>
                        <p className="text-[32px] xl:text-[64px] bebas text-[#CECECE]">{t('referralprogram.7')}</p>
                    </div>
                    <div className="xl:w-[445px] h-[175px] rounded-[40px] bg-white flex flex-col justify-center items-center">
                        <p className="text-[16px] xl:text-[24px] roboto">{t('referralprogram.8')}</p>
                        <p className="text-[32px] xl:text-[64px] bebas text-[#CECECE]">{t('referralprogram.9')}</p>
                    </div>
                </div>
                <div className="xl:w-[910px] h-[175px] rounded-[40px] bg-white mt-[20px] flex flex-col justify-center items-center xl:mx-auto relative">
                    <p className="text-[16px] xl:text-[24px] roboto text-center">{t('referralprogram.10')}</p>
                    <p className="text-[32px] xl:text-[64px] bebas text-[#CECECE]">{t('referralprogram.11')}</p>
                    <Fade right><img src={reffer} alt="" className="absolute right-[-100px] bottom-[-40px] hidden xl:block"/></Fade>
                </div>
            </div>  
        </div>
    );
}

export default Earn;