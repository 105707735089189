import React from "react";

import { useTranslation } from "react-i18next";

import frame from '../../img/Crypto/frame.png'

function StartTrading () {

    const { t } = useTranslation();
 
    return(
        <div className="overflow-hidden relative max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[120px]">
            <div className="hidden xl:block relative">
                <img src={frame} alt="" className="w-full h-full"/>
                <div className="absolute inset-[50px] top-[120px]">
                    <p className="text-white roboto leading-[1.4] text-[32px] max-w-[620px]">{t('crypto.14')}</p>
                    <div className="button_main mt-[40px]">
                        <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                    </div>
                </div>
            </div>
            <div className="block xl:hidden">
                <div className="h-[279px] rounded-[40px] bg-[#1E1E1E]">
                    <p className="text-white roboto leading-[1.4] text-[20px] mx-[20px] pt-[60px]">{t('crypto.14')}</p>
                    <a href="https://user.ameno-platform.ltd/login">
                        <div className="button_main mt-[40px] mx-[20px]">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default StartTrading;