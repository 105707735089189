import React, { useEffect } from "react";

import Main from '../components/Investing/Main'
import BeInvestor from "../components/Investing/BeInvestor";
import Ready from "../components/Home/Ready";

function Investing () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <BeInvestor/>
            <Ready/>
        </div>
    );
}

export default Investing;