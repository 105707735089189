
import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main1 from '../../img/AboutUs/main1.png'
import main2 from '../../img/AboutUs/main2.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="text-[24px] xl:text-[32px] roboto">{t('about.1')}</p>
                    <p className="text-[32px] xl:text-[90px] bebas max-w-[600px] leading-[1.2] mt-[6px]"><Trans>{t('about.2')}</Trans></p>
                    <p className="roboto text-[#000000B2] max-w-[600px] font-[300] mt-[20px]">{t('about.3')}</p>
                </div>
                <div className="relative xl:mt-[-150px]">
                    <Fade right><img src={main1} alt="" /></Fade>
                </div>
            </div>
            <div className="flex flex-col-reverse xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[50px] xl:mt-[100px] items-center">
                <div>
                    <Fade left><img src={main2} alt="" /></Fade>
                </div>
                <div>
                    <p className="roboto leading-[1.4] text-[20px] xl:text-[40px]">{t('about.4')}</p>
                    <p className="max-w-[600px] mt-[20px] font-[300] text-[14px] xl:text-[16px] text-[#000000B2]">{t('about.5')}</p>
                    <p className="roboto leading-[1.4] text-[20px] xl:text-[40px] mt-[20px] xl:mt-[60px]">{t('about.6')}</p>
                    <p className="max-w-[600px] mt-[20px] font-[300] text-[14px] xl:text-[16px] text-[#000000B2]">{t('about.7')}</p>
                </div>
            </div>
        </div>
    );
}

export default Main;