import React from "react";

import { useTranslation } from "react-i18next";

import c1 from '../../img/Home/c1.png'
import c2 from '../../img/Home/c2.png'
import c3 from '../../img/Home/c3.png'

import vector from '../../img/Home/vector.png'

import Fade from "react-reveal/Fade";

function Allinone () {

    const { t } = useTranslation();
 
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative overflow-hidden mt-[80px] xl:mt-[160px]">
            <p className="roboto text-[24px] xl:text-[40px]">{t('main.41')}</p>
            <div className="flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px] gap-[20px] xl:gap-0">
                <Fade bottom>
                    <div className="xl:w-[393px] h-[361px] rounded-[40px] bg-white flex flex-col items-center">
                        <img src={c1} alt="" className="ml-[40px] mt-[40px] mr-auto"/>
                        <img src={vector} alt="" className="mt-[-65px]"/>
                        <p className="text-[20px] xl:text-[24px] roboto mx-[40px] mt-[20px]">{t('main.42')}</p>
                    </div>
                    <div className="xl:w-[393px] h-[361px] rounded-[40px] bg-white flex flex-col items-center">
                        <img src={c2} alt="" className="ml-[40px] mt-[40px] mr-auto"/>
                        <p className="text-[20px] xl:text-[24px] roboto mx-[40px] mt-[60px]">{t('main.43')}</p>
                    </div>
                    <div className="xl:w-[393px] h-[361px] rounded-[40px] bg-white flsex flex-col items-center">
                        <img src={c3} alt="" className="ml-[40px] mt-[40px] mr-auto"/>
                        <p className="text-[20px] xl:text-[24px] roboto mx-[40px] mt-[60px]">{t('main.44')}</p>
                    </div>
                </Fade>
            </div>
        </div>
       
    );
}

export default Allinone;