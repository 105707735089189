import React from "react";

import { useTranslation, Trans } from "react-i18next";

import i1 from '../../img/ReferralProgram/iphone1.png'
import i2 from '../../img/ReferralProgram/iphone2.png'

function HowDoesYour () {

    const { t } = useTranslation();
 
    return(
        <div className="relative mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
               <p className="text-[32px] xl:text-[40px] leading-[1.2] roboto"><Trans>{t('referralprogram.22')}</Trans></p>
               <div className="flex flex-col xl:flex-row mt-[30px] xl:mt-[60px] justify-between">
                <div className="flex flex-col justify-between">
                    <div className="flex items-end gap-[20px]">
                        <p className="bebas text-[#CECECE] text-[32px] xl:text-[100px] leading-[0.6]">01</p>
                        <div className="h-px w-[414px] bg-[#CECECE]"></div>
                    </div>
                    <p className="text-[20px] xl:text-[32px] roboto text-[#000000B2] max-w-[517px] mt-[20px] xl:mt-0">{t('referralprogram.23')}</p>
                </div>
                <div className="xl:w-[600px] h-[300px] xl:h-[558px] rounded-[40px] bg-white flex items-end justify-center mt-[20px] xl:mt-0">
                    <img src={i1} alt="" className="w-[200px] xl:w-auto"/>
                </div>  
               </div>
               <div className="flex flex-col xl:flex-row-reverse mt-[40px] xl:mt-[80px] justify-between">
                <div className="flex flex-col justify-between">
                    <div className="flex items-end gap-[20px]">
                        <p className="bebas text-[#CECECE] text-[32px] xl:text-[100px] leading-[0.6]">02</p>
                        <div className="h-px w-[414px] bg-[#CECECE]"></div>
                    </div>
                    <p className="text-[20px] xl:text-[32px] roboto text-[#000000B2] max-w-[517px] mt-[20px] xl:mt-0">{t('referralprogram.24')}</p>
                </div>
                <div className="xl:w-[600px] h-[300px] xl:h-[558px] rounded-[40px] bg-white flex items-end justify-center mt-[20px] xl:mt-0">
                    <img src={i2} alt="" className="w-[200px] xl:w-auto"/>
                </div>  
               </div>
            </div>  
        </div>
    );
}

export default HowDoesYour;