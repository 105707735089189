import React, { useEffect, useState } from "react";
import $ from 'jquery';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { images } from "./imagesToLoad";

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Investing from "./pages/Investing";
import Crypto from "./pages/Crypto";
import Iras from "./pages/Iras";
import Banking from "./pages/Banking";
import ReferralProgram from "./pages/ReferralProgram";
import AccountTypes from "./pages/AccountTypes";
import AboutUs from "./pages/AboutUs";

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 
  return (
    <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/investing" element={<Investing />} />
          <Route path="/crypto" element={<Crypto />} />
          <Route path="/iras" element={<Iras />} />
          <Route path="/banking" element={<Banking />} />
          <Route path="/referral" element={<ReferralProgram />} />
          <Route path="/accounts" element={<AccountTypes />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;