import React, { useEffect } from "react";

import Main from '../components/Banking/Main'
import HereShow from "../components/Banking/HereShow";
import WhatDoes from "../components/Banking/WhatDoes";
import FaqB from "../components/Banking/FaqB";

function Banking () {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <HereShow/>
            <WhatDoes/>
            <FaqB/>
        </div>
    );
}

export default Banking;