import React from "react";

import { useTranslation, Trans } from "react-i18next";

import check from '../../img/Crypto/check.png'

function AdvantagesofReferral () {

    const { t } = useTranslation();
 
    return(
        <div className="relative mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <div className="flex flex-col xl:flex-row gap-[15px]">
                    <p className="text-[32px] xl:text-[40px] leading-[1.2] roboto max-w-[574px]">{t('referralprogram.25')}</p>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px]">
                    <div className="flex flex-col justify-between">
                        <div className="flex gap-[30px] items-center">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px] spandiscover"><Trans>{t('referralprogram.26')}</Trans></p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px] spandiscover"><Trans>{t('referralprogram.27')}</Trans></p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px] spandiscover"><Trans>{t('referralprogram.28')}</Trans></p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between mt-[25px] xl:mt-0">
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px] spandiscover"><Trans>{t('referralprogram.29')}</Trans></p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px] spandiscover"><Trans>{t('referralprogram.30')}</Trans></p>
                        </div>
                        <div className="flex gap-[30px] items-center mt-[25px]">
                            <img src={check} alt="" />
                            <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] max-w-[529px] spandiscover"><Trans>{t('referralprogram.31')}</Trans></p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default AdvantagesofReferral;