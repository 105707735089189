import React from "react";

import { useTranslation } from "react-i18next";

import blick from '../../img/Home/blick.png'
import phone from '../../img/Home/phone.png'

import Fade from "react-reveal/Fade";

function Ready () {

    const { t } = useTranslation();
 
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[80px] xl:mt-[160px]">
            <div className="xl:h-[305px] bg-white rounded-[40px] relative flex flex-col">
                <div className="xl:absolute top-[40px] left-[100px] mx-auto mt-[60px] xl:mt-0 flex flex-col items-center z-50">
                    <p className="text-[24px] xl:text-[40px] roboto">{t('main.45')}</p>
                    <p className="text-[20px] xl:text-[32px] roboto font-[300] mt-[12px] xl:mt-0">{t('main.46')}</p>
                    <a href="https://user.ameno-platform.ltd/login" >
                        <div className="button_main mt-[40px] xl:mt-[55px]">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
                <div className="xl:relative overflow-hidden w-full h-full">
                    <Fade><img src={blick} alt="" className="xl:top-[-300px] xl:right-0 absolute"/></Fade>
                </div>
                <Fade right><img src={phone} alt="" className="xl:absolute right-[200px] top-[-23px] mx-[40px] max-w-[calc(100%-80px)] xl:mx-0 mt-[100px] xl:mt-0 z-50"/>   </Fade>  
            </div>
        </div>
       
    );
}

export default Ready;