
import React from "react";

import { useTranslation } from "react-i18next";

import one from '../../img/AboutUs/1.png'
import two from '../../img/AboutUs/2.png'
import three from '../../img/AboutUs/3.png'
import four from '../../img/AboutUs/4.png'

import Fade from "react-reveal/Fade";

function WhyTrade () {

    const { t } = useTranslation();

    return(
        <div className="overflow-hidden relative pt-[35px] xl:pt-[70px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <p className="text-[20px] xl:text-[40px]">{t('about.8')}</p>
                <div className="flex flex-col xl:flex-row gap-[20px] mt-[20px] xl:mt-[60px]">
                    <div className="xl:w-[497px] h-[281px] rounded-[40px] bg-white relative">
                        <p className="mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[40px] roboto text-[16px] xl:text-[20px]">{t('about.9')}</p>
                        <div className="w-[105px] xl:w-[210px] h-px bg-[#0000004D] mx-[20px] xl:mx-[40px] mt-[10px]"></div>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[40px] z-50 relative">{t('about.10')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[12px] max-w-[210px] font-[300] mx-[20px] xl:mx-[40px]">{t('about.11')}</p>
                        <img src={one} alt="" className="absolute right-0 top-0"/>
                    </div>
                    <div className="xl:w-[497px] h-[281px] rounded-[40px] bg-[#131313] relative overflow-hidden">
                        <p className="mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[40px] roboto text-[16px] xl:text-[20px] text-[#FFF]">{t('about.12')}</p>
                        <div className="w-[105px] xl:w-[210px] h-px bg-[#FFFFFF4D] mx-[20px] xl:mx-[40px] mt-[10px]"></div>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2] mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[40px]">{t('about.13')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#FFFFFFB2] mt-[12px] max-w-[210px] font-[300] mx-[20px] xl:mx-[40px]">{t('about.14')}</p>
                        <img src={two} alt="" className="absolute right-[-100px] xl:right-0 top-[20px]"/>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row gap-[20px] mt-[20px] xl:ml-auto">
                    <div className="xl:w-[497px] h-[281px] rounded-[40px] bg-[#00D1FF] relative overflow-hidden">
                        <p className="mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[40px] roboto text-[16px] xl:text-[20px]">{t('about.15')}</p>
                        <div className="w-[105px] xl:w-[210px] h-px bg-[#0000004D] mx-[20px] xl:mx-[40px] mt-[10px]"></div>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[40px]">{t('about.16')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[12px] max-w-[210px] font-[300] mx-[20px] xl:mx-[40px]">{t('about.17')}</p>
                        <img src={three} alt="" className="absolute right-[-100px] xl:right-0 top-0"/>
                    </div>
                    <div className="xl:w-[497px] h-[281px] rounded-[40px] bg-[#CECECE] relative overflow-hidden">
                        <p className="mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[40px] roboto text-[16px] xl:text-[20px]">{t('about.18')}</p>
                        <div className="w-[105px] xl:w-[210px] h-px bg-[#0000004D] mx-[20px] xl:mx-[40px] mt-[10px]"></div>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[40px]">{t('about.19')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2] mt-[12px] max-w-[210px] font-[300] mx-[20px] xl:mx-[40px]">{t('about.20')}</p>
                        <img src={four} alt="" className="absolute right-[-100px] xl:right-0 top-[20px]"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyTrade;