import React from "react";

import { useTranslation } from "react-i18next";

import rec from '../../img/Iras/rec.png'
import pig from '../../img/Iras/pig.png'

import Fade from "react-reveal/Fade";

function Active () {

    const { t } = useTranslation();
 
    return(
        <div className="max-w-[1280px] xl:mx-auto mx-[20px] relative mt-[80px] xl:mt-[160px]">
            <div className="h-[516px] xl:h-[305px] rounded-[40px] overflow-hidden">
                <img src={rec} alt="" className="w-full h-full object-cover"/>
                <div className="absolute top-[50px] left-[20px] xl:left-[80px]">
                    <p className="text-white roboto text-[32px] xl:text-[48px] leading-[1.4]">{t('irs.20')}</p>
                    <p className="text-white roboto text-[16px] xl:text-[24px] leading-[1.2] font-[300]">{t('irs.21')}</p>
                    <a href="https://user.ameno-platform.ltd/login">
                        <div className="button_main mt-[40px]">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
                <img src={pig} alt="" className="absolute bottom-[20px] xl:top-[-30px] right-0"/>
            </div>
        </div>
    );
}

export default Active;