import React, { useEffect } from "react";

import Main from '../components/Crypto/Main'
import StartTrading from "../components/Crypto/StartTrading";
import Faq from "../components/Crypto/Faq";

function Crypto () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#E8E8E8]">
            <Main/>
            <StartTrading/>
            <Faq/>
        </div>
    );
}

export default Crypto;