import React from "react";

import { useTranslation, Trans } from "react-i18next";

import check from '../../img/Crypto/check.png'

import Fade from "react-reveal/Fade";

function Discover () {

    const { t } = useTranslation();
 
    return(
        <div className="max-w-[1280px] xl:mx-auto mx-[20px] relative mt-[80px] xl:mt-[160px]">
            <p className="text-[32px] xl:text-[40px] leading-[1.2] roboto">{t('irs.13')}</p>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px]">
                <div className="flex items-center gap-[50px]">
                    <img src={check} alt="" />
                    <p className="spandiscover roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[529px]"><Trans>{t('irs.14')}</Trans></p>
                </div>
                <div className="flex items-center gap-[50px] mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="spandiscover roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[529px]"><Trans>{t('irs.15')}</Trans></p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px]">
                <div className="flex items-center gap-[50px]">
                    <img src={check} alt="" />
                    <p className="spandiscover roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[529px]"><Trans>{t('irs.16')}</Trans></p>
                </div>
                <div className="flex items-center gap-[50px] mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="spandiscover roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[529px]"><Trans>{t('irs.17')}</Trans></p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px]">
                <div className="flex items-center gap-[50px]">
                    <img src={check} alt="" />
                    <p className="spandiscover roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[529px]"><Trans>{t('irs.18')}</Trans></p>
                </div>
                <div className="flex items-center gap-[50px] mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="spandiscover roboto text-[14px] xl:text-[16px] text-[#000000B2] max-w-[529px]"><Trans>{t('irs.19')}</Trans></p>
                </div>
            </div>
        </div>
    );
}

export default Discover;